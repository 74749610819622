import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Footer(){
    return(
        <Fragment>
        <section className="slogan-style2-area">
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <div className="slogan-style2-content">
                        <div className="title">
                            <h2>We’re Delivering the Best<br /> Customer Experience</h2>
                        </div>
                        <div className="button-box">
                            <Link className="btn-one" to=""><span className="txt">Quick Enquiy</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="bottom-parallax">
   
    <footer className="footer-area">
      
        <div className="footer">
            <div className="container">
                <div className="row text-right-rtl">

                   
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div className="single-footer-widget single-footer-widget--company-info marbtm50">
                            <div className="our-company-info">
                                <div className="text-box">
                                    <p>43, Jean Macnamara Street Maccgregor, ACT - 2615 </p>
                                </div>
                                <h2><Link to="tel:+61423685106">+61 423 685 106</Link></h2>
                                <h3><Link to="mailto:info@osmosisglobal.com">info@osmosisglobal.com</Link></h3>
                                <div className="footer-social-link">
                                    <ul className="clearfix">
                                        <li>
                                            <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                                        </li>
                                        <li>
                                            <Link to="#"><i className="fab fa-instagram"></i></Link>
                                        </li>
                                        <li>
                                            <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                                        </li>
                                        <li>
                                            <Link to="#"><i className="fab fa-whatsapp"></i></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                   
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <div className="single-footer-widget single-footer-widget--link-box marbtm50">
                            <div className="title">
                                <h3>Explore</h3>
                            </div>
                            <div className="footer-widget-links">
                                <ul>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/our-people">Meet the Team</Link></li>
                                    <li><Link to="/success-stories">Success Stories</Link></li>
                                    <li><Link to="/news">Latest News</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                                <ul className="right">
                                    <li><Link to="">Support</Link></li>
                                    <li><Link to="">Terms of Use</Link></li>
                                    <li><Link to="">Privacy Policy</Link></li>
                                    <li><Link to="">Help</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                        <div className="single-footer-widget">
                            <div className="title">
                                <h3>Newsletter</h3>
                            </div>
                            <form className="newsletter-form" action="#">
                                <input type="email" name="email" placeholder="Email Address" />
                                <button className="btn-one" type="submit">
                                    <span className="txt">Subscribe</span>
                                </button>
                                <div className="checked-box1">
                                    <input type="checkbox" name="newsletter-check" id="newsletter" checked="" />
                                    <label for="newsletter">
                                        <span></span>I agree to all terms and policies
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                   

                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="bottom-inner">
                    <div className="footer-logo-style1">
                        <Link to="index.html">
                            <img src="./assets/images/logo-whte.png" alt="Osmosis Logo" title="" />
                        </Link>
                    </div>
                    <div className="copyright">
                        <p>Copyright &copy; 2023 <Link to="index.php">Osmosis Australia</Link> All Rights Reserved.</p>
                    </div>

                </div>
            </div>
        </div>

    </footer>
</div>
</Fragment>
)
}

export default Footer;