import React, { Fragment } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function ProgrammeDetails()
{
    return(
        <Fragment>
        <Header />
        <section className="breadcrumb-area">
            <div className="breadcrumb-area-bg" style={{backgroundImage: "url(assets/images/slider-1.jpg)"}}>
            </div>
            <div className="shape-box"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content">

                            <div className="breadcrumb-menu">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="active">Services</li>
                                </ul>
                            </div>
                            <div className="title aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                <h2>Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="service-details-area">
            <div className="container">
                <div className="row">

                    
                    <div className="col-xl-4 col-lg-5 order-box-2">
                        <div className="service-details__sidebar">

                            <div className="view-all-service">
                                <ul className="service-pages">
                                    <li className="active">
                                        <Link to="services-single-1.html">
                                            Consumer Product <span className="icon-right-arrow"></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="services-single-2.html">
                                            Banking Advising <span className="icon-right-arrow"></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="services-single-3.html">
                                            Marketing Rules <span className="icon-right-arrow"></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="services-single-4.html">
                                            Business Growth <span className="icon-right-arrow"></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="services-single-5.html">
                                            Audit Marketing <span className="icon-right-arrow"></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="services-single-6.html">
                                            Financial Advice <span className="icon-right-arrow"></span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="service-details-contact-info text-center">
                                <div className="sidebar-info-box-bg" style={{backgroundImage: "url(assets/images/sidebar-info-box-bg.jpg)"}} ></div>
                                <div className="icon">
                                    <span className="icon-phone-call"></span>
                                </div>
                                <h3>Contact with<br /> us for any<br /> advice</h3>
                                <h2><Link to="tel:+50033333">+3-(856) 000-9850</Link></h2>
                            </div>

                            <div className="info-download-box">
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <span className="fa fa-download"></span>
                                        </div>
                                        <div className="title">
                                            <h5><Link to="#">Download our Broucher</Link></h5>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    
                    <div className="col-xl-8 col-lg-7 order-box-1">
                        <div className="service-details__content">
                            <div className="img-box-outer">
                                <div className="img-box1">
                                    <img src="./assets/images/services/service-details-1-img1.jpg" alt="" />
                                </div>
                                <div className="icon">
                                    <span className="icon-creative"></span>
                                </div>
                            </div>

                            <div className="text-box1">
                                <h2>Consumer Product</h2>
                                <p>
                                    Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui
                                    dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta
                                    sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit
                                    amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s.
                                </p>
                                <p>
                                    It has survived not only five centuries. Lorem Ipsum is simply dummy text of the new
                                    design printng and type setting Ipsum take a look at our round. When an unknown
                                    printer took a galley of type and scrambled it to make a type specimen book. It has
                                    survived not only five centuries, but also the leap into electronic typesetting.
                                </p>
                            </div>

                            <div className="text-box2">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="text-box2-single aos-init" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">
                                            <p>Refresing to get such a touch. Duis aute irure dolor in oluptate.</p>
                                        </div>
                                    </div>

                                    <div className="col-xl-6">
                                        <div className="text-box2-single aos-init" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1500">
                                            <p>Velit esse cillum eu fugiat pariatur. Duis aute irure dolor in in
                                                voluptate.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-box3">
                                <p>When an unknown printer took a galley of type and scrambled it to make a type
                                    specimen book. It has survived not only five centuries, but also the leap into
                                    electronic typesetting. Lorem Ipsum has been the ndustry standard dummy text ever
                                    since the 1500s.</p>
                            </div>

                            <div className="text-box4">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="img-box">
                                            <img src="./assets/images/services/service-details-1-img-2.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="content-box">
                                            <h2>Service Benefits</h2>
                                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                            </p>

                                            <ul>
                                                <li>
                                                    <span className="icon-check"></span> In id diam nec nisi
                                                    congue tincidunt
                                                </li>
                                                <li>
                                                    <span className="icon-check"></span> Pn malesuada purus a ligula
                                                    dapibus
                                                </li>
                                                <li>
                                                    <span className="icon-check"></span> Vestibulum tincidunt arcu vel
                                                    nisl
                                                </li>
                                                <li>
                                                    <span className="icon-check"></span> Sed tristique lorem non
                                                    tesque
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="service-details-faq-content">
                                <ul className="accordion-box">
                                    <li className="accordion block active-block">
                                        <div className="acc-btn active">
                                            <div className="icon-outer">
                                                <i className="icon-down-arrow"></i>
                                            </div>
                                            <h3>Interdum et malesuada fames ac ante ipsum</h3>
                                        </div>
                                        <div className="acc-content current">
                                            <p>Suspendisse finibus urna mauris, vitae consequat quam vel. Vestibulum leo
                                                ligula, vit commodo nisl Sed luctus venenatis pellentesque.</p>
                                        </div>
                                    </li>
                                    <li className="accordion block">
                                        <div className="acc-btn">
                                            <div className="icon-outer">
                                                <i className="icon-down-arrow"></i>
                                            </div>
                                            <h3>Maecenas condimentum sollicitudin ligula.</h3>
                                        </div>
                                        <div className="acc-content">
                                            <p>Suspendisse finibus urna mauris, vitae consequat quam vel. Vestibulum leo
                                                ligula, vit commodo nisl Sed luctus venenatis pellentesque.</p>
                                        </div>
                                    </li>
                                    <li className="accordion block">
                                        <div className="acc-btn">
                                            <div className="icon-outer">
                                                <i className="icon-down-arrow"></i>
                                            </div>
                                            <h3>Duis rhoncus orci ut metus rhoncus.</h3>
                                        </div>
                                        <div className="acc-content">
                                            <p>Suspendisse finibus urna mauris, vitae consequat quam vel. Vestibulum leo
                                                ligula, vit commodo nisl Sed luctus venenatis pellentesque.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                 

                </div>
            </div>
        </section>

        <Footer />

        </Fragment>
    );
}

export default ProgrammeDetails;