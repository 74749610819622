import React, { Fragment } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { Link } from "react-router-dom";

function Media()
{
    return(
        <Fragment>
        <Header />
        <section className="blog-style1-area">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="icon">
                                <span className="icon-heartbeat"></span>
                            </div>
                            <div className="sub-title">
                                <h3>Latest Blog Posts</h3>
                            </div>
                            <h2>News &amp; Articles</h2>
                        </div>
                        <div className="row">

                           
                            <div className="col-xl-4 col-lg-12">
                                <div className="single-blog-style1">
                                    <div className="img-holder">
                                        <img src="assets/images/news1.jpg" alt="" />
                                        <div className="date-box">
                                            <p>20 oct</p>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="meta-info">
                                            <ul>
                                                <li><span className="icon-user"></span><Link to="#">by Admin</Link></li>
                                                <li><span className="icon-conversation"></span><Link to="#">2 Comments</Link></li>
                                            </ul>
                                        </div>
                                        <h3><Link to="">Accounting Support During the Exponential Growth</Link></h3>
                                        <div className="text">
                                            <p>There are not many of passages of lorem alteration in some form.
                                                Donec scelerisque dolor id nunc dictum, interdum gravida.</p>
                                        </div>
                                        <div className="btn-box">
                                            <Link to="blog-single.html">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="col-xl-4 col-lg-12">
                                <div className="single-blog-style1">
                                    <div className="img-holder">
                                    <img src="assets/images/news1.jpg" alt="" />
                                        <div className="date-box">
                                            <p>15 oct</p>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="meta-info">
                                            <ul>
                                                <li><span className="icon-user"></span><Link to="#">by Admin</Link></li>
                                                <li><span className="icon-conversation"></span><Link to="#">2 Comments</Link></li>
                                            </ul>
                                        </div>
                                        <h3><Link to="blog-single.html">How to Manage Your Business’s Online Reputation</Link></h3>
                                        <div className="text">
                                            <p>There are not many of passages of lorem alteration in some form.
                                                Donec scelerisque dolor id nunc dictum, interdum gravida.</p>
                                        </div>
                                        <div className="btn-box">
                                            <Link to="blog-single.html">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-xl-4 col-lg-12">
                                <div className="single-blog-style1">
                                    <div className="img-holder">
                                        <img src="assets/images/news1.jpg" alt="" />
                                        <div className="date-box">
                                            <p>10 oct</p>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="meta-info">
                                            <ul>
                                                <li><span className="icon-user"></span><Link to="#">by Admin</Link></li>
                                                <li><span className="icon-conversation"></span><Link to="#">2 Comments</Link></li>
                                            </ul>
                                        </div>
                                        <h3><Link to="blog-single.html">If You Want to be a Great Leader Shut Up and Just
                                                Listen</Link></h3>
                                        <div className="text">
                                            <p>There are not many of passages of lorem alteration in some form.
                                                Donec scelerisque dolor id nunc dictum, interdum gravida.</p>
                                        </div>
                                        <div className="btn-box">
                                            <Link to="blog-single.html">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           

                        </div>
                    </div>
                </section>

                <Footer />
                </Fragment>
    )
}
export default Media;