import React, { Fragment } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Contact()
{
    return(

        <Fragment>
        <Header />
        <section className="breadcrumb-area">
            <div className="breadcrumb-area-bg" style={{backgroundImage: "url(assets/images/slider-1.jpg)"}}>
            </div>
            <div className="shape-box"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content">

                            <div className="breadcrumb-menu">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="active">Contact Us</li>
                                </ul>
                            </div>
                            <div className="title aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="google-map-area">
            <div class="container">
            <div className="contact-info-box">
                        <div className="left-box">
                            <h2><Link to="tel:+61 423 685 106">+61 423 685 106</Link></h2>
                            <h3><Link to="mailto:info@osmosisglobal.com">info@osmosisglobal.com</Link>
                             <Link to="mailto:agentvarghese@gmail.com">agentvarghese@gmail.com</Link></h3>
                        </div>
                        <div className="middle-box">
                            <p>43, Jean Macnamara Street<br />Maccgregor, ACT - 2615</p>
                        </div>
                        <div className="right-box">
                            <div className="thm-social-link1">
                                <ul className="clearfix">
                                    <li>
                                        <Link to="/"><i className="fab fa-facebook-f"></i></Link>
                                    </li>
                                    <li>
                                        <Link to="/"><i className="fab fa-instagram"></i></Link>
                                    </li>
                                    <li>
                                        <Link to="/"><i className="fab fa-linkedin-in"></i></Link>
                                    </li>
                                    <li>
                                        <Link to="/"><i className="fab fa-whatsapp"></i></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
        </section>
        <section className="main-contact-form-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="sub-title">
                        <div className="border-box"></div>
                        <h3>Contact with us</h3>
                    </div>
                    <h2>Write a Message</h2>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="contact-form">
                            <form id="contact-form" name="contact_form" className="default-form2" action="assets/inc/sendmail.php" method="post" novalidate="novalidate">

                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <div className="input-box">
                                                <input type="text" name="form_name" id="formName" placeholder="Full Name" required="" aria-required="true" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <div className="input-box">
                                                <input type="email" name="form_email" id="formEmail" placeholder="Email Address" required="" aria-required="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <div className="input-box">
                                                <input type="text" name="form_phone" value="" id="formPhone" placeholder="Phone" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <div className="input-box">
                                                <input type="text" name="form_subject" value="" id="formSubject" placeholder="Subject" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="form-group">
                                            <div className="input-box">
                                                <textarea name="form_message" id="formMessage" placeholder="Write a Message" required="" aria-required="true"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12 text-center">
                                        <div className="button-box">
                                            <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                                            <button className="btn-one" type="submit" data-loading-text="Please wait...">
                                                <span className="txt">
                                                    send a message
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <Footer />

        </Fragment>


    );
}
export default Contact;