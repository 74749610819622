import React, { Fragment } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function OurPeople()
{
    return(
        
        <Fragment>
            <Header />

            <section className="breadcrumb-area">
            <div className="breadcrumb-area-bg" style={{backgroundImage: "url(assets/images/slider-1.jpg)"}}>
            </div>
            <div className="shape-box"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content">

                            <div className="breadcrumb-menu">
                                <ul>
                                    <li><Link to="index.html">Home</Link></li>
                                    <li className="active">Meet Our People</li>
                                </ul>
                            </div>
                            <div className="title aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                <h2>Meet Our People</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="team-style1-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="sub-title">
                        <div className="border-box"></div>
                        <h3>Our team members</h3>
                    </div>
                    <h2>Meet Our Experts</h2>
                </div>
                <div className="row">
                  
                    <div className="col-xl-4 col-lg-4 col-md-12">
                        <div className="single-team-style1 wow fadeInUp animated" >
                            <div className="img-holder">
                                <div className="inner">
                                    <img src="assets/images/team_1.png" alt="" />
                                </div>
                                <div className="title-holder text-center">
                                    <h2><Link to="#">VARGHESE PC</Link></h2>
                                    <h3>CO-FOUNDER & CONSULTANT</h3>
                                    <ul className="social-links">
                                        <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-whatsapp"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    
                    <div className="col-xl-4 col-lg-4 col-md-12">
                        <div className="single-team-style1 wow fadeInUp animated" >
                            <div className="img-holder">
                                <div className="inner">
                                    <img src="assets/images/team_2.png" alt="" />
                                </div>
                                <div className="title-holder text-center">
                                    <h2><Link to="#">JOHN BARRY</Link></h2>
                                    <h3>MANAGING DIRECTOR</h3>
                                    <ul className="social-links">
                                        <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-whatsapp"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>


            <Footer />
        </Fragment>
    )
}

export default OurPeople;