import React, { Fragment } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function About()
{
    return(
        <Fragment>
            <Header />

            <section className="breadcrumb-area">
            <div className="breadcrumb-area-bg" style={{backgroundImage: "url(assets/images/slider-1.jpg)"}}>
            </div>
            <div className="shape-box"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content">

                            <div className="breadcrumb-menu">
                                <ul>
                                    <li><Link to="index.html">Home</Link></li>
                                    <li className="active">About Us</li>
                                </ul>
                            </div>
                            <div className="title aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                <h2>About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="about-style2-area">
            <div className="container">
                <div className="row">

                    <div className="col-xl-6">
                        <div className="about-style1__image clearfix">
                            <div className="shape-1"></div>
                            <div className="shape-2"></div>
                            <div className="inner">
                                <img src="./assets/images/about-style2__image-1.jpg" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className="about-style2__content about-style2__content--in-about-style3">
                            <div className="sec-title sec-title--style2">
                                <div className="sub-title">
                                    <div className="border-box"></div>
                                    <h3>About Osmosis</h3>
                                </div>
                                <h2>Get to Know About<br /> Consulting</h2>
                            </div>
                            <div className="inner-content">
                                <div className="top-text">
                                    <div className="icon">
                                        <span className="icon-recruit"></span>
                                    </div>
                                    <div className="inner-title">
                                        <h3><span>Envisioned by enthusiastic professionals, the Osmosis Group provides professional and customer focussed services to all clients. </span></h3>
                                    </div>
                                </div>
                                <br />
                                Our people are as intelligent and driven as they are passionate and caring. We work in a fast-paced, dynamic and forward-thinking environment with talented professionals who share pride and enthusiasm for what they do.

                                <div className="signature-box">
                                    <h2>VARGHESE P C <span>- CO-FOUNDER & CONSULTANT</span></h2>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </section>


        <section className="slogan-area">
            <div className="slogan-area__bg"
                style={{backgroundImage: "url(./assets/images/bgd.jpg)"}}></div>
            <div className="shape wow slideInRight" data-wow-delay="1400ms" data-wow-duration="5500ms"
                style={{backgroundImage: "url(./assets/images/shape-1.png)"}}>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="slogan-text-box">
                            <h2 className="paroller">We Provide <span
                                    style={{backgroundImage: "url(./assets/images/zig-zag.png)"}}>professional and customer </span><br />
                                    focused services <br />to all clients.</h2>
                            <div className="slogan-btn-box paroller">
                                <Link className="btn-one" to="about.html">
                                    <span className="txt">Discover More</span>
                                </Link>
                            </div>

                            <div className="parallax-scene parallax-scene-1">
                                <div data-depth="0.20" className="parallax-layer shape">
                                    <div className="experience-box">
                                        <h2>30</h2>
                                        <p className="curved-circle">Years of<br /> Experience</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

       
        
            
            <Footer />
        </Fragment>
    )
}
export default About;