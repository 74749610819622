import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import Header from './Header';
import HomeMiddle from './HomeMiddle';
import Footer from './Footer';
import {useEffect} from 'react';
 
import './App.css';
import './assets/css/animate.css';
import './assets/css/aos.css';
import './assets/css/bootstrap.min.css';
import './assets/css/custom-animate.css';
import './assets/css/fancybox.min.css';
import './assets/css/jquery.bootstrap-touchspin.css';
import './assets/css/magnific-popup.css';
import './assets/css/nice-select.css';
import './assets/css/owl.css';
import './assets/css/rtl.css';
import './assets/css/scrollbar.css';
import './assets/css/swiper.min.css';

import './assets/css/header-section.css';
import './assets/css/banner-section.css';
import './assets/css/about-section.css';
import './assets/css/blog-section.css';
import './assets/css/faq-section.css';
import './assets/css/contact-page.css';
import './assets/css/breadcrumb-section.css';
import './assets/css/team-section.css';
import './assets/css/partner-section.css';
import './assets/css/testimonial-section.css';
import './assets/css/services-section.css';
import './assets/css/footer-section.css';

import './assets/css/theme-color.css';
import './assets/css/style.css';
import './assets/css/responsive.css';




function Home()
{
    const [isShow, invokeModal] = React.useState(false);
    const initModal = () => {
        return invokeModal(false)
    }
    const handleClose = () => invokeModal(false)

    useEffect(() => {
        initModal();
    }, []);
   

    return (
        <Fragment> 
      <Modal show={isShow}  onHide={handleClose}>       
        <Modal.Body>
          <img src='./assets/images/osmosis-21-5-2022.jpg' alt="" />
        </Modal.Body>   
        
      </Modal>


        <div  className="boxed_wrapper ltr">          
           <Header />
           <HomeMiddle />
           <Footer />

       </div>
       </Fragment>
       
    )
}


export default Home;