import React, { Fragment } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function Programmes()
{
    return(
        <Fragment>
        <Header />
        <section className="breadcrumb-area">
            <div className="breadcrumb-area-bg" style={{backgroundImage: "url(assets/images/slider-1.jpg)"}}>
            </div>
            <div className="shape-box"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content">

                            <div className="breadcrumb-menu">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="active">Our Programmes</li>
                                </ul>
                            </div>
                            <div className="title aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                <h2>Our Programmes</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="blog-style1-area">
            <div class="container">
                <div class="sec-title text-center">
                    <div class="icon">
                        <span class="icon-heartbeat"></span>
                    </div>
                    <div class="sub-title">
                        <h3>Latest Blog Posts</h3>
                    </div>
                    <h2>News &amp; Articles</h2>
                </div>
                <div class="row">                   
                   
                    <div class="col-xl-4 col-lg-12">
                        <div class="single-blog-style1 aos-init">
                            <div class="img-holder">
                                <img src="./assets/images/blog/blog-v1-1.jpg" alt="" />
                                <div class="date-box">
                                    <p>20 oct</p>
                                </div>
                            </div>
                            <div class="text-holder">
                                <div class="meta-info">
                                    <ul>
                                        <li><span class="icon-user"></span><Link to="#">by Admin</Link></li>
                                        <li><span class="icon-conversation"></span><Link to="#">2 Comments</Link></li>
                                    </ul>
                                </div>
                                <h3><Link to="blog-single.html">Accounting Support During the Exponential Growth</Link></h3>
                                <div class="btn-box">
                                    <Link to="blog-single.html">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                   
                    <div class="col-xl-4 col-lg-12">
                        <div class="single-blog-style1 aos-init">
                            <div class="img-holder">
                                <img src="./assets/images/blog/blog-v1-2.jpg" alt="" />
                                <div class="date-box">
                                    <p>15 oct</p>
                                </div>
                            </div>
                            <div class="text-holder">
                                <div class="meta-info">
                                    <ul>
                                        <li><span class="icon-user"></span><Link to="#">by Admin</Link></li>
                                        <li><span class="icon-conversation"></span><Link to="#">2 Comments</Link></li>
                                    </ul>
                                </div>
                                <h3><Link to="blog-single.html">How to Manage Your Business’s Online Reputation</Link></h3>
                                <div class="btn-box">
                                    <Link to="blog-single.html">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                  
                    <div class="col-xl-4 col-lg-12">
                        <div class="single-blog-style1 aos-init">
                            <div class="img-holder">
                                <img src="./assets/images/blog/blog-v1-3.jpg" alt="" />
                                <div class="date-box">
                                    <p>10 oct</p>
                                </div>
                            </div>
                            <div class="text-holder">
                                <div class="meta-info">
                                    <ul>
                                        <li><span class="icon-user"></span><Link to="#">by Admin</Link></li>
                                        <li><span class="icon-conversation"></span><Link to="#">2 Comments</Link></li>
                                    </ul>
                                </div>
                                <h3><Link to="blog-single.html">If You Want to be a Great Leader Shut Up and Just
                                        Listen</Link></h3>
                                <div class="btn-box">
                                    <Link to="blog-single.html">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                  

                </div>
            </div>
        </section>

        <Footer />

        </Fragment>
    );
}

export default Programmes;