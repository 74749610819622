import React, { Fragment } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function SpotLights()
{
    return(
        <Fragment>
            <Header />

            <section className="breadcrumb-area">
            <div className="breadcrumb-area-bg" style={{backgroundImage: "url(assets/images/slider-1.jpg)"}}>
            </div>
            <div className="shape-box"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content">

                            <div className="breadcrumb-menu">
                                <ul>
                                    <li><Link to="">Home</Link></li>
                                    <li className="active">About Us</li>
                                </ul>
                            </div>
                            <div className="title aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                <h2>About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<section class="service-style1-area pdbtm100">
    <div class="shape1 wow slideInLeft">
        <img class="rotate-me" src="./assets/images/shape/service-style1-shape1.png" alt="" />
    </div>
    <div class="container">
        <div class="sec-title text-center">
            <div class="sub-title">
                <p>Quality Services</p>
            </div>
            <h2>We’re Prodviding Quality<br /> Roofing Services</h2>
        </div>
        <div class="row">
           
            <div class="col-xl-4 col-lg-12">
                <div class="single-service-style1 text-center wow fadeInUp animated">
                    <div class="img-holder">
                        <div class="inner">
                            <img src="./assets/images/services/service-v1-1.jpg" alt="" />
                        </div>
                        <div class="shape-bg" style={{backgroundImage: "url(assets/images/service-image-shape.png)"}}></div>
                    </div>
                    <div class="title-holder">
                        <div class="icon">
                            <div class="shape1"></div>
                            <span class="flaticon-roof"></span>
                        </div>
                        <h3><a href="services-details.html">Roofing Layer</a></h3>
                        <div class="inner-text">
                            <p>Lorem ipsum dolor amet consectetur sed do eiusmod.</p>
                        </div>
                        <div class="readmore-button">
                            <a href="services-details.html"><span class="flaticon-right-arrow-1"></span></a>
                        </div>
                    </div>
                </div>
            </div> 
          
            <div class="col-xl-4 col-lg-12">
                <div class="single-service-style1 text-center wow fadeInUp animated">
                    <div class="img-holder">
                        <div class="inner">
                            <img src="./assets/images/services/service-v1-2.jpg" alt="" />
                        </div>
                        <div class="shape-bg" style={{backgroundImage: "url(assets/images/service-image-shape.png)"}}></div>
                    </div>
                    <div class="title-holder">
                        <div class="icon">
                            <div class="shape1"></div>
                            <span class="flaticon-broken-house"></span>
                        </div>
                        <h3><a href="services-details.html">Roof Repair</a></h3>
                        <div class="inner-text">
                            <p>Lorem ipsum dolor amet consectetur sed do eiusmod.</p>
                        </div>
                        <div class="readmore-button">
                            <a href="services-details.html"><span class="flaticon-right-arrow-1"></span></a>
                        </div>
                    </div>
                </div>
            </div> 
           
           
            <div class="col-xl-4 col-lg-12">
                <div class="single-service-style1 text-center wow fadeInUp animated">
                    <div class="img-holder">
                        <div class="inner">
                            <img src="./assets/images/services/service-v1-3.jpg" alt="" />
                        </div>
                        <div class="shape-bg" style={{backgroundImage: "url(assets/images/service-image-shape.png)"}}></div>
                    </div>
                    <div class="title-holder">
                        <div class="icon">
                            <div class="shape1"></div>
                            <span class="flaticon-wrench"></span>
                        </div>
                        <h3><a href="services-details.html">Roof Renovation</a></h3>
                        <div class="inner-text">
                            <p>Lorem ipsum dolor amet consectetur sed do eiusmod.</p>
                        </div>
                        <div class="readmore-button">
                            <a href="services-details.html"><span class="flaticon-right-arrow-1"></span></a>
                        </div>
                    </div>
                </div>
            </div> 
          
            <div class="col-xl-4 col-lg-12">
                <div class="single-service-style1 text-center wow fadeInUp">
                    <div class="img-holder">
                        <div class="inner">
                            <img src="./assets/images/services/service-v1-4.jpg" alt="" />
                        </div>
                        <div class="shape-bg" style={{backgroundImage: "url(assets/images/service-image-shape.png)"}}></div>
                    </div>
                    <div class="title-holder">
                        <div class="icon">
                            <div class="shape1"></div>
                            <span class="flaticon-roof-1"></span>
                        </div>
                        <h3><a href="services-details.html">Siding Corner</a></h3>
                        <div class="inner-text">
                            <p>Lorem ipsum dolor amet consectetur sed do eiusmod.</p>
                        </div>
                        <div class="readmore-button">
                            <a href="services-details.html"><span class="flaticon-right-arrow-1"></span></a>
                        </div>
                    </div>
                </div>
            </div> 
           
            <div class="col-xl-4 col-lg-12">
                <div class="single-service-style1 text-center wow fadeInUp">
                    <div class="img-holder">
                        <div class="inner">
                            <img src="./assets/images/services/service-v1-5.jpg" alt="" />
                        </div>
                        <div class="shape-bg" style={{backgroundImage: "url(assets/images/service-image-shape.png)"}}></div>
                    </div>
                    <div class="title-holder">
                        <div class="icon">
                            <div class="shape1"></div>
                            <span class="flaticon-roof-3"></span>
                        </div>
                        <h3><a href="services-details.html">Roof Installation</a></h3>
                        <div class="inner-text">
                            <p>Lorem ipsum dolor amet consectetur sed do eiusmod.</p>
                        </div>
                        <div class="readmore-button">
                            <a href="services-details.html"><span class="flaticon-right-arrow-1"></span></a>
                        </div>
                    </div>
                </div>
            </div> 
           
            <div class="col-xl-4 col-lg-12">
                <div class="single-service-style1 text-center wow fadeInUp">
                    <div class="img-holder">
                        <div class="inner">
                            <img src="./assets/images/services/service-v1-6.jpg" alt="" />
                        </div>
                        <div class="shape-bg" style={{backgroundImage: "url(assets/images/service-image-shape.png)"}}></div>
                    </div>
                    <div class="title-holder">
                        <div class="icon">
                            <div class="shape1"></div>
                            <span class="flaticon-roof-4"></span>
                        </div>
                        <h3><a href="services-details.html">Corner Fixing</a></h3>
                        <div class="inner-text">
                            <p>Lorem ipsum dolor amet consectetur sed do eiusmod.</p>
                        </div>
                        <div class="readmore-button">
                            <a href="services-details.html"><span class="flaticon-right-arrow-1"></span></a>
                        </div>
                    </div>
                </div>
            </div> 
           
          
        </div>
    </div>
</section>
<Footer />


        </Fragment>
    );
}
export default SpotLights;