import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel'; 
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

// Import react-circular-progressbar module and styles
import {
    CircularProgressbar,
    buildStyles
  } from "react-circular-progressbar";
  import "react-circular-progressbar/dist/styles.css";
  
  // Animation
  import ChangingProgressProvider from "./ChangingProgressProvider";
  
 

function HomeMiddle(){
    return(
        <Fragment>
         <section className="main-slider style2">
        <OwlCarousel items={1}  
          className="owl-theme"  
          loop  
          autoplay
          animateOut='fadeOut'
          animateIn='fadeIn'
          margin={8} >  
           <div style={{backgroundImage: "url(./assets/images/slider-1.jpg)"}}><div className="slide">
                        <div className="image-layer" style={{backgroundImage: "url(./assets/images/slider-1.jpg)"}}>
                        </div>
                        <div className="auto-container">
                            <div className="content text-center">
                                <div className="big-title">
                                    <h2>
                                    WORK IN  AUSTRALIA
                                    </h2>
                                    <h3>Work in Australia and enjoy an exceptionally high standard of living</h3>
                                    <h4>Incredible employment and career opportunities are available in Australia</h4>
                                </div>
                                <div className="btns-box">
                                    <Link className="btn-one" to="">
                                        <span className="txt">
                                            discover more<i className="icon-refresh arrow"></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>  

            <div style={{backgroundImage: "url(./assets/images/slider-2.jpg)"}}><div className="slide">
                        <div className="image-layer" style={{backgroundImage: "url(./assets/images/slider-1.jpg)"}}>
                        </div>
                        <div className="auto-container">
                            <div className="content text-center">
                                <div className="big-title">
                                    <h2>
                                    WORK IN  AUSTRALIA
                                    </h2>
                                    <h3>Work in Australia and enjoy an exceptionally high standard of living</h3>
                                    <h4>Incredible employment and career opportunities are available in Australia</h4>
                                </div>
                                <div className="btns-box">
                                    <Link className="btn-one" to="">
                                        <span className="txt">
                                            discover more<i className="icon-refresh arrow"></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>  

           
      </OwlCarousel>  
      </section>  

             <section className="main-slider style2">
            <div className="slider-box">
                <div className="banner-carousel owl-theme owl-carousel">
                    <div className="slide">
                        <div className="image-layer" style={{backgroundImage: "url(./assets/images/slider-1.jpg)"}}>
                        </div>
                        <div className="auto-container">
                            <div className="content text-center">
                                <div className="big-title">
                                    <h2>
                                    WORK IN  AUSTRALIA
                                    </h2>
                                    <h3>Work in Australia and enjoy an exceptionally high standard of living</h3>
                                    <h4>Incredible employment and career opportunities are available in Australia</h4>
                                </div>
                                <div className="btns-box">
                                    <Link className="btn-one" to="">
                                        <span className="txt">
                                            discover more<i className="icon-refresh arrow"></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="image-layer" style={{backgroundImage: "url(./assets/images/slider-2.jpg)"}}>
                        </div>
                        <div className="auto-container">
                            <div className="content text-center">
                                <div className="big-title">
                                    <h2>DESTINATION AUSTRALIA</h2>
                                    <h4>Make your Australian Dreams come true</h4>
                                </div>
                                <div className="btns-box">
                                    <Link className="btn-one" to="">
                                        <span className="txt">
                                            discover more<i className="icon-refresh arrow"></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                </div>
            </div>
        </section>
        <section class="about-style2-area">
            <div class="container">
			<div class="sec-title text-center">
                    <div class="sub-title">
                        <div class="border-box"></div>
                        <h3>our introductions</h3>
                    </div>
                    <h2>We’re Providing the<br /> Best Solution for Your Future</h2>
                </div>
                <div class="row">
					
                    <div class="col-xl-6">
                        <div class="about-style2__content">
                            
                            <div class="inner-content">
                                <div class="top-text">
                                    <div class="icon">
                                       <i class="fal fa-quote-left"></i>
                                    </div>
                                    <div class="inner-title">
                                        <h3>There are many variations of passa of lorem ipsum available, but the
                                            majority have suffered alteration.</h3>
                                    </div>
                                </div>

                                <div class="text-box">
                                    <p>There are many variations of passages of lorem free market to available, but the
                                        majority have alteration in some form, by injected humour, or randomised words.
                                    </p>
                                </div>

                                             <div class="row">
                                    <div class="col-xl-6">
                                        <div class="progress-block">
                                            <div class="inner-box">
                                                <div class="graph-outer">
                                                    <ChangingProgressProvider values={[0, 80, 9]}>
                                                        {value => (
                                                        <CircularProgressbar
                                                            value={value}
                                                            text={`${value}%`}
                                                            circleRatio={0.75}
                                                            styles={buildStyles({
                                                            rotation: 1 / 2 + 1 / 8,
                                                            strokeLinecap: "butt",
                                                            trailColor: "#eee"
                                                            })}
                                                        />
                                                        )}
                                                    </ChangingProgressProvider>
                                                </div>
                                            </div>
                                            <div class="outer-text">
                                                <h3>Client<br /> Satisfied</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="progress-block">
                                            <div class="inner-box">
                                                <div class="graph-outer">
                                                <ChangingProgressProvider values={[0, 50, 9]}>
                                                        {value => (
                                                        <CircularProgressbar
                                                            value={value}
                                                            text={`${value}%`}
                                                            circleRatio={0.75}
                                                            styles={buildStyles({
                                                            rotation: 1 / 2 + 1 / 8,
                                                            strokeLinecap: "butt",
                                                            trailColor: "#eee"
                                                            })}
                                                        />
                                                        )}
                                                    </ChangingProgressProvider>
                                                    
                                                </div>
                                            </div>
                                            <div class="outer-text">
                                                <h3>Finance<br /> Consulting</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="signature-box">
                                    <h2>Kevin Martin <span>- CO Founder</span></h2>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6">
                        <div class="about-style2__image clearfix">
                            <div class="shape-1"></div>
                            <div class="shape-2"></div>
                            <div class="border-box float-bob-y"></div>
                            <div class="inner">
                                <img src="./assets/images/about-style2__image-1.jpg" alt="" />
                                <div class="overlay-content">
                                    <div class="count-outer count-box">
                                        <span class="count-text" data-speed="3000" data-stop="4800">4800</span>
                                    </div>
                                    <h3>Visas Completed</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

<section className="service-element-page02">
<div className="container">
    <div className="sec-title text-center">
        <div className="sub-title">
            <div className="border-box"></div>
            <h3>We Provide Wide Range of
        Visa Categories</h3>
        </div>
        <h2>Visa Categories</h2>
    </div>
    <div className="row text-right-rtl">

        <div className="col-xl-12">
       
        <OwlCarousel items={3}  
          className="theme_carousel service-element-carousel owl-theme owl-carousel owl-nav-style-one rtl-carousel"  
          loop  
          autoplay
          animateOut='fadeOut'
          animateIn='fadeIn'
          margin={8} 
          navText='<span className="left icon-right-arrow"></span>",
          "<span className="right icon-right-arrow"></span>' >  
             <div className="single-service-style2">
                    <div className="img-holder">
                        <div className="inner">
                            <img src="./assets/images/vc-1.jpg" alt="" />
                        </div>
                        <div className="icon">
                           <img src="./assets/images/icn-1.png" alt="" />
                        </div>
                    </div>
                    <div className="title-holder">
                        <h3><Link to="">Visitor visas </Link></h3>
                        <div className="text">
                            <p>
                               This visa stream lets you visit Australia as a tourist, to see family and friends or for purposes other than business or medical treatment.
                            </p>
                        </div>
                        <div className="btn-box">
                            <Link to="#"><span className="icon-right-arrow"></span></Link>
                        </div>
                    </div>
                </div>
                <div className="single-service-style2">
                    <div className="img-holder">
                        <div className="inner">
                            <img src="./assets/images/vc-2.jpg" alt="" />
                        </div>
                        <div className="icon">
                           <img src="./assets/images/icn-1.png" alt="" />
                        </div>
                    </div>
                    <div className="title-holder">
                        <h3><Link to="">Studying and Training visas</Link></h3>
                        <div className="text">
                            <p>
                               This visa stream lets you visit Australia as a tourist, to see family and friends or for purposes other than business or medical treatment.
                            </p>
                        </div>
                        <div className="btn-box">
                            <Link to="#"><span className="icon-right-arrow"></span></Link>
                        </div>
                    </div>
                </div>
             
                <div className="single-service-style2">
                    <div className="img-holder">
                        <div className="inner">
                            <img src="./assets/images/vc-3.jpg" alt="" />
                        </div>
                        <div className="icon">
                           <img src="./assets/images/icn-1.png" alt="" />
                        </div>
                    </div>
                    <div className="title-holder">
                        <h3><Link to="services-details.html">Family and partner visas</Link></h3>
                        <div className="text">
                            <p>
                               This visa stream lets you visit Australia as a tourist, to see family and friends or for purposes other than business or medical treatment.
                            </p>
                        </div>
                        <div className="btn-box">
                            <Link to="#"><span className="icon-right-arrow"></span></Link>
                        </div>
                    </div>
                </div>
               
                <div className="single-service-style2">
                    <div className="img-holder">
                        <div className="inner">
                            <img src="./assets/images/vc-4.jpg" alt="" />
                        </div>
                        <div className="icon">
                           <img src="./assets/images/icn-1.png" alt="" />
                        </div>
                    </div>
                    <div className="title-holder">
                        <h3><Link to="services-details.html">Working and skilled visas</Link></h3>
                        <div className="text">
                            <p>
                               This visa stream lets you visit Australia as a tourist, to see family and friends or for purposes other than business or medical treatment.
                            </p>
                        </div>
                        <div className="btn-box">
                            <Link to="#"><span className="icon-right-arrow"></span></Link>
                        </div>
                    </div>
                </div>
          </OwlCarousel>  



           
        </div>

    </div>
</div>
</section>

<section className="slogan-area">
            <div className="slogan-area__bg"
                style={{backgroundImage: "url(./assets/images/bgd.jpg)"}}></div>
            <div className="shape wow slideInRight" data-wow-delay="1400ms" data-wow-duration="5500ms"
                style={{backgroundImage: "url(./assets/images/shape-1.png)"}}>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="slogan-text-box">
                            <h2 className="paroller">We Provide <span
                                    style={{backgroundImage: "url(./assets/images/zig-zag.png)"}}>professional and customer </span><br />
                                    focused services <br />to all clients.</h2>
                            <div className="slogan-btn-box paroller">
                                <Link className="btn-one" to="about.html">
                                    <span className="txt">Discover More</span>
                                </Link>
                            </div>

                            <div className="parallax-scene parallax-scene-1">
                                <div data-depth="0.20" className="parallax-layer shape">
                                    <div className="experience-box">
                                        <h2>30</h2>
                                        <p className="curved-circle">Years of<br /> Experience</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="case-element-page02">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="icon">
                        <span className="icon-heartbeat"></span>
                    </div>
                    <div className="sub-title">
                        <h3>Programmes</h3>
                    </div>
                    <h2>Popular Programmes</h2>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                    <OwlCarousel items={2}  
                            className="theme_carousel service-element-carousel owl-theme owl-carousel owl-nav-style-one rtl-carousel"  
                            loop  
                            autoplay
                            animateOut='fadeOut'
                            animateIn='fadeIn'
                            margin={8} 
                            navText='<i className="fa fa-chevron-left"></i>",
                            "<i className="fa fa-chevron-right"></i>' > 

                            <div className="case-stories__single">
                                <div className="img-box">
                                    <img src="./assets/images/pgm-1.jpg" alt="" />
                                </div>
                                <div className="content-box">
                                    <div className="top-text">
                                        <h2>Business Growth <br />Through Expert <br /> Leadership</h2>
                                        <div className="line"></div>
                                    </div>
                                    <div className="bottom-text">
                                        <div className="text">
                                            <p>When an unknown printer took a galley<br /> type and it to make a type
                                                book.
                                            </p>
                                        </div>
                                        <div className="icon">
                                            <Link to="#"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="case-stories__single">
                                <div className="img-box">
                                    <img src="./assets/images/pgm-2.jpg" alt="" />
                                </div>
                                <div className="content-box">
                                    <div className="top-text">
                                        <h2>The Marketing<br /> Advice and Risk<br /> Mangement</h2>
                                        <div className="line"></div>
                                    </div>
                                    <div className="bottom-text">
                                        <div className="text">
                                            <p>When an unknown printer took a galley<br /> type and it to make a type
                                                book.
                                            </p>
                                        </div>
                                        <div className="icon">
                                            <Link to="#"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
						  
                            <div className="case-stories__single">
                                <div className="img-box">
                                    <img src="./assets/images/pgm-1.jpg" alt="" />
                                </div>
                                <div className="content-box">
                                    <div className="top-text">
                                        <h2>Business Growth <br />Through Expert <br /> Leadership</h2>
                                        <div className="line"></div>
                                    </div>
                                    <div className="bottom-text">
                                        <div className="text">
                                            <p>When an unknown printer took a galley<br /> type and it to make a type
                                                book.
                                            </p>
                                        </div>
                                        <div className="icon">
                                            <Link to="#"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="case-stories__single">
                                <div className="img-box">
                                     <img src="./assets/images/pgm-3.jpg" alt="" />
                                </div>
                                <div className="content-box">
                                    <div className="top-text">
                                        <h2>The Marketing<br /> Advice and Risk<br /> Mangement</h2>
                                        <div className="line"></div>
                                    </div>
                                    <div className="bottom-text">
                                        <div className="text">
                                            <p>When an unknown printer took a galley<br /> type and it to make a type
                                                book.
                                            </p>
                                        </div>
                                        <div className="icon">
                                            <Link to="#"><span className="icon-right-arrow"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </OwlCarousel>



                        
                    </div>

                </div>
            </div>
        </section>

        <section className="features-style1-area">
            <div className="features-style1-img-box">
                <div className="features-style1-img-bg"
                    style={{backgroundImage: "url(./assets/images/bg2.jpg)"}} >
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5">
                        <div className="features-style1__content">
                            <div className="sec-title sec-title--style2">
                                <div className="sub-title">
                                    <div className="border-box"></div>
                                    <h3>What’s Happening</h3>
                                </div>
                                <h2>Our <br /> Services</h2>
                            </div>
                            <div className="inner-content">
                                <ul>
                                    <li>
                                        <span className="icon-right-arrow"></span>
                                        Nsectetur cing elit.
                                    </li>
                                    <li>
                                        <span className="icon-right-arrow"></span>
                                        Suspe ndisse suscipit sagittis leo.
                                    </li>
                                    <li>
                                        <span className="icon-right-arrow"></span>
                                        Entum estibulum dignissim posuere.
                                    </li>
                                </ul>
                                <div className="btn-box">
                                    <Link className="btn-one" to="about.html">
                                        <span className="txt">Discover More</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-7">
                        <div className="features-style1__items">
                            <ul>
                                <li>
                                    <div className="top">
                                        <div className="icon">
                                             <i className="fal fa-passport "></i>
                                        </div>
                                        <div className="title">
                                            <h3>Service<br /> Name</h3>
                                        </div>
                                    </div>
                                    <div className="inner-text">
                                        <p>Lorem ipsum dolor sit a consetetur simple is pscing elitr m nonmy simply free
                                            text.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="top">
                                        <div className="icon">
                                           <i className="fal fa-passport "></i>
                                        </div>
                                        <div className="title">
                                            <h3>Service<br /> Name</h3>
                                        </div>
                                    </div>
                                    <div className="inner-text">
                                        <p>Lorem ipsum dolor sit a consetetur simple is pscing elitr m nonmy simply free
                                            text.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>
      
	  
        <section className="features-style2-area">
            <div className="features-style2-area__bg"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="features-style2__content">
                            <div className="features-style2__content-bg"
                                style={{backgroundImage: "url(./assets/images/ftr-bgd.jpg)"}}>
                            </div>
                            <div className="shape-1"></div>
                            <div className="shape-2"></div>
                            <div className="top-title">
                                <h2>We Shape the Perfect<br /> Solution for Your Future</h2>
                            </div>
                            <div className="features-style2__content-inner">
                                <div className="row">

                                   
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="features-style2-single-box">
                                           <img src="./assets/images/ftr-icn-1.png" alt="" />
                                            <div className="inner-title">
                                                <h3>Trusted Agency</h3>
                                                <p>Morbi nec finibus misd</p>
                                            </div>
                                        </div>
                                    </div>
                                   
                                   
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="features-style2-single-box">
                                            <img src="./assets/images/ftr-icn-2.png" alt="" />
                                            <div className="inner-title">
                                                <h3>Quality Services</h3>
                                                <p>Morbi nec finibus misd</p>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="features-style2-single-box">
                                            <img src="./assets/images/ftr-icn-3.png" alt="" />
                                            <div className="inner-title">
                                                <h3>Best Strategy</h3>
                                                <p>Morbi nec finibus misd</p>
                                            </div>
                                        </div>
                                    </div>
                                  

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
	   
        <section className="blog-style1-area">
            <div className="blog-style1-area__bg"></div>
            <div className="container">
                <div className="sec-title text-center">
                    <div className="sub-title">
                        <div className="border-box"></div>
                        <h3>Blog</h3>
                    </div>
                    <h2>Spot Lights</h2>
                </div>
                <div className="row">
                   
                    <div className="col-xl-4 col-lg-12">
                        <div className="single-blog-style1">
                            <div className="img-holder">
                               <img src="./assets/images/spl-1.jpg" alt="" />
                                <div className="date-box">
                                    <p>20 oct</p>
                                </div>
                            </div>
                            <div className="text-holder">
                                <div className="meta-info">
                                    <ul>
                                        <li><i className="fal fa-user"></i><Link to="#"> by Admin</Link></li>
                                        <li><i className="fal fa-comments"></i><Link to="#"> 2 Comments</Link></li>
                                    </ul>
                                </div>
                                <h3><Link to="">Immigration Update: Coronavirus</Link></h3>
								<p>The spread of the coronavirus has caused entry and exit bans, quarantines and travel restrictions throughout the world. Check this page for up-to-date information on this fluid situation.</p>
                                <div className="btn-box">
                                    <Link to="blog-single.html">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                 
				 
                    <div className="col-xl-4 col-lg-12">
                        <div className="single-blog-style1">
                            <div className="img-holder">
                               <img src="./assets/images/spl-2.jpg" alt="" />
                                <div className="date-box">
                                    <p>20 oct</p>
                                </div>
                            </div>
                            <div className="text-holder">
                                <div className="meta-info">
                                    <ul>
                                        <li><i className="fal fa-user"></i><Link to="#"> by Admin</Link></li>
                                        <li><i className="fal fa-comments"></i><Link to="#"> 2 Comments</Link></li>
                                    </ul>
                                </div>
                                <h3><Link to="">Immigration Update: Coronavirus</Link></h3>
								<p>The spread of the coronavirus has caused entry and exit bans, quarantines and travel restrictions throughout the world. Check this page for up-to-date information on this fluid situation.</p>
                                <div className="btn-box">
                                    <Link to="blog-single.html">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
				   
                    <div className="col-xl-4 col-lg-12">
                        <div className="single-blog-style1">
                            <div className="img-holder">
                               <img src="./assets/images/spl-3.jpg" alt="" />
                                <div className="date-box">
                                    <p>20 oct</p>
                                </div>
                            </div>
                            <div className="text-holder">
                                <div className="meta-info">
                                    <ul>
                                        <li><i className="fal fa-user"></i><Link to="#"> by Admin</Link></li>
                                        <li><i className="fal fa-comments"></i><Link to="#"> 2 Comments</Link></li>
                                    </ul>
                                </div>
                                <h3><Link to="">Immigration Update: Coronavirus</Link></h3>
								<p>The spread of the coronavirus has caused entry and exit bans, quarantines and travel restrictions throughout the world. Check this page for up-to-date information on this fluid situation.</p>
                                <div className="btn-box">
                                    <Link to="blog-single.html">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                  				  


                </div>
            </div>
        </section>
</Fragment>
    )
}

export default HomeMiddle;