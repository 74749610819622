import React, { Fragment } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function Services()
{
    return(
        <Fragment>
        <Header />
        <section className="breadcrumb-area">
            <div className="breadcrumb-area-bg" style={{backgroundImage: "url(assets/images/slider-1.jpg)"}}>
            </div>
            <div className="shape-box"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content">

                            <div className="breadcrumb-menu">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="active">Services</li>
                                </ul>
                            </div>
                            <div className="title aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                <h2>Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="service-page">
            <div className="container">
                <div className="row">

                    
                    <div className="col-xl-4 col-lg-4 wow fadeInUp animated">
                        <div className="single-service-style2">
                            <div className="img-holder">
                                <div className="inner">
                                <img src="./assets/images/study.jpg" alt=""/>
                                </div>
                                <div className="icon">
                                <img src="./assets/images/icn-1.png" alt=""/>
                                </div>
                            </div>
                            <div className="title-holder">
                                <h3><Link to="/study">STUDY</Link></h3>
                                <div className="text">
                                    <p>
                                    We provide advise on the different options available for studies in Australia. We ensure your education (and migration) application best suits your needs and personal circumstances. In association with Minerva Education Group, we bring the best of Australian Education System within your reach to take your career to the next level. 
                                    </p>
                                </div>
                                <div className="btn-box">
                                    <Link to="/study"><span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-xl-4 col-lg-4 wow fadeInDown animated">
                        <div className="single-service-style2">
                            <div className="img-holder">
                                <div className="inner">
                                    <img src="./assets/images/work.jpg" alt="" />
                                </div>
                                <div className="icon">
                                <img src="./assets/images/icn-1.png" alt=""/>
                                </div>
                            </div>
                            <div className="title-holder">
                                <h3><Link to="/work">WORK</Link></h3>
                                <div className="text">
                                    <p>
                                    To work in Australia you need to have a job offer from a Company based in Australia. We can support you in getting a job offer and we can then arrange suitable work visas. Our Recruitment and Placement wing supports clients who lodge their visa applications through us. 
                                    </p>
                                </div>
                                <div className="btn-box">
                                    <Link to="/work"><span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-4 wow fadeInUp animated">
                        <div className="single-service-style2">
                            <div className="img-holder">
                                <div className="inner">
                                    <img src="./assets/images/migrate.jpg" alt="" />
                                </div>
                                <div className="icon">
                                   <img src="./assets/images/icn-1.png" alt=""/>
                                </div>
                            </div>
                            <div className="title-holder">
                                <h3><Link to="/migrate">MIGRATE</Link></h3>
                                <div className="text">
                                    <p>
                                    If you are a skilled professional, you can make Australia your home based on your education and work experience. We can help professionals get PR visas and state sponsorships, as well as assessment from relevant assessing bodies like Vetassess, ACS, EA in Australia. 
                                    </p>
                                </div>
                                <div className="btn-box">
                                    <Link to="/migrate"><span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                   
                    
                  
                 

                </div>
            </div>
        </section>

        <Footer />

        </Fragment>
    );
}

export default Services;