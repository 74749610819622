import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter  as Router,Routes  , Route} from 'react-router-dom';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import About from './pages/About';
import OurPeople from './pages/OurPeople';
import Media from './pages/Media';
import PhotoGallery from './pages/PhotoGallery';
import Services from './pages/Services';
import ServiceDetails from './pages/ServiceDetails';
import Programmes from './pages/Programmes';
import ProgrammeDetails from './pages/ProgrammeDetails';
import Contact from './pages/Contact';
import SpotLights from './pages/SpotLights';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouteAll />
  </React.StrictMode>
);

export default function RouteAll() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/our-people" element={<OurPeople />} />
          <Route path="/media" element={<Media />} />
           <Route path="/gallery" element={<PhotoGallery />} />
           <Route path="/services" element={<Services />} />
           <Route path="/servicedetails" element={<ServiceDetails />} />
           <Route path="/programmes" element={<Programmes />} />
           <Route path="/programmedetails" element={<ProgrammeDetails />} />
           <Route path="/spotlights" element={<SpotLights />} />
           <Route path="/contact" element={<Contact />} />
           
      </Routes>
    </Router>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
