import React  from 'react';
import { Link } from 'react-router-dom';

import logo from './assets/images/logo.png';

function Header()
{
    
        return (
            
            <header  className="main-header header-style-two">

                <div  className="header-style2__top">
                    <div  className="container">
                        <div  className="outer-box">
                            <div  className="header-style2__top-left">
                                <div id="ticker">
                                <div  className="title">NEWS</div>
                                <ul >
                                    
                                    <li>Student visa requirements for studying in Australia: </li>
                                    <li>Student visa requirements for studying in Australia: </li>
                                    
                                </ul>
                                
                                </div>
                            </div>

                            <div  className="header-style2__top-right">
                            
                                <div  className="header-social-link">
                                    <ul  className="clearfix">
                                    <li  className="top-mnu"><Link to="/careers">Careers</Link>
                                    </li>
                                    
                                    <li  className="top-mnu"><Link to="/contact">Contact Us</Link>
                                    </li>
                                    <li><Link to="/subscribe"  className="btn-style-one theme-btn">Subscribe</Link></li>
                                    <li><Link to="/login"  className="btn-style-one theme-btn">Login / Sign Up</Link></li>
                                        <li>
                                            <Link to="/"  className="scl-icn">
                                                <i  className="fab fa-facebook-f"></i>
                                            </Link>
                                        </li>
                                        <li>
                                        <Link to="/"  className="scl-icn">
                                            <i  className="fab fa-whatsapp"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/" className="scl-icn">
                                            <i  className="fab fa-linkedin-in"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/"  className="scl-icn">
                                            <i  className="fab fa-instagram"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div  className="header-style2">
                    <div  className="container">
                        <div  className="outer-box">
                        
                            <div  className="header-style2__left">
                                <div  className="logo-box-style2">
                                    <Link to="/">
                                        <img src={logo} alt="Osmosis Logo" title="" />
                                    </Link>
                                </div>

                                <div  className="nav-outer style2 clearfix">
                                    <div  className="mobile-nav-toggler">
                                        <div  className="inner">
                                            <span  className="icon-bar"></span>
                                            <span  className="icon-bar"></span>
                                            <span  className="icon-bar"></span>
                                        </div>
                                    </div>
                                
                                    <nav  className="main-menu style2 navbar-expand-md navbar-light">
                                        <div  className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                    <ul  className="navigation clearfix">
                                        <li  className=""><Link to="/about">About Us</Link></li>
                                        <li  className=""><Link to="/our-people">Our People</Link></li>
                                        <li  className="dropdown"><Link to="/spotlights">Spot Lights</Link>
                                            <ul>
                                                <li><Link to="/">Spot light list-1</Link></li>
                                                <li><Link to="/">Spot light list-2</Link></li>
                                                <li><Link to="/">Spot light list-3</Link></li>
                                                
                                            
                                            </ul>
                                        </li>
                                        <li  className="dropdown"><Link to="/services">Services</Link>
                                            <ul>
                                                <li><Link to="/study">Study</Link></li>
                                                <li><Link to="/work">Work</Link></li>
                                                <li><Link to="/migrate">Migrate</Link></li>
                                            </ul>
                                        </li>
                                        
                                        <li  className="dropdown"><Link to="/programmes">Our Programmes</Link>
                                            <ul>
                                                <li><Link to="/gcep_programme">GCEP Programme</Link></li>
                                                <li><Link to="/">Student Programme</Link></li>
                                            </ul>
                                        </li>
                                        <li  className="dropdown"><Link to="">Media</Link>
                                            <ul>
                                                <li><Link to="/gallery/">Photo Gallery</Link></li>
                                                <li><Link to="/">Video Gallery</Link></li>
                                                <li><Link to="/">News & Updates</Link></li>
                                            </ul>
                                        </li>
                                        
                                    </ul>
                                        </div>
                                    </nav>
                            
                                </div>

                            

                            </div>
                        
                            

                        </div>
                    </div>
                </div>


                <div  className="sticky-header">
                    <div  className="container">
                        <div  className="clearfix">
                        
                            <div  className="logo float-left">
                                <Link to="/"  className="img-responsive">
                                    <img src="./assets/images/logo.png" alt="" title="" />
                                </Link>
                            </div>
                        
                            <div  className="right-col float-right">
                            
                                <nav  className="main-menu clearfix">
                                <ul  className="navigation clearfix">
                                        <li  className=""><Link to="/about">About Us</Link>
                                    </li>
                                        <li  className=""><Link to="/our-people">Our People</Link>
                                    </li>
                                        <li  className="dropdown"><Link to="/">Spot Lights</Link>
                                            <ul>
                                                <li><Link to="/">Spot light list-1</Link></li>
                                                <li><Link to="/">Spot light list-2</Link></li>
                                                <li><Link to="/">Spot light list-3</Link></li>
                                                
                                            
                                            </ul>
                                        </li>
                                        <li  className="dropdown"><Link to="/services">Services</Link>
                                            <ul>
                                                <li><Link to="/study">Study</Link></li>
                                                <li><Link to="/work">Work</Link></li>
                                                <li><Link to="/migrate">Migrate</Link></li>
                                            </ul>
                                        </li>
                                        
                                        <li  className="dropdown"><Link to="">Our Programmes</Link>
                                            <ul>
                                                <li><Link to="/gcep_programme">GCEP Programme</Link></li>
                                                <li><Link to="/">Student Programme</Link></li>
                                            </ul>
                                        </li>
                                        <li  className="dropdown"><Link to="">Media</Link>
                                            <ul>
                                                <li><Link to="/gallery/">Photo Gallery</Link></li>
                                                <li><Link to="/">Video Gallery</Link></li>
                                                <li><Link to="/">News & Updates</Link></li>
                                            </ul>
                                        </li>
                                        
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>




                </header>

               
        )
    
}

export default Header;